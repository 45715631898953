import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import {
  localeSelector,
  languageCodeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import { specialPartnerSelector } from '@wxu/contexts/src/partner/selectors';
import {
  interpolateUrl,
  urlTranslatorSelector,
} from '@wxu/translations';
import { privacyHeaderSelector, serverPageStateQuerySelector } from '@wxu/contexts/src/page/selectors';
import {
  newsletterEnabledSelector,
  validateNewsletterEnabledLink,
} from '@wxu/contexts/src/newsletter/selectors';
import { isKoreanCSCCodeSelector } from '@wxu/contexts/src/page';
// Do not mutate imported array and its objects, cloneDeep
import { US_REGIMES, USA_CCPA } from '@wxu/dpr-sdk/src/configs/regimesConfig';
import {
  LEGAL_LINKS,
  TERMS_OF_USE_LINKS,
  PRIVACY_POLICY_LINKS,
  CALIFORNIA_NOTICE_LINK,
} from './LegalLinks.const';
import { CORPORATE_LINKS } from './CorporateLinks.const';
import { SOCIAL_LINKS } from './SocialLinks.const';

function validateLocale(locale, linkObject) {
  // Filter out excluded links
  if (linkObject.excludeLocales && linkObject.excludeLocales.includes(locale)) return false;
  // Filter out non-included links
  if (linkObject.includeLocales && !linkObject.includeLocales.includes(locale)) return false;

  // Return true if no filtering required
  return true;
}

function validatePartner(partner, linkObject) {
  // Filter out excluded links
  if (linkObject.excludePartners && linkObject.excludePartners.includes(partner)) return false;

  // Return true if no filtering required
  return true;
}

// Interpolate and Filter Corporate and Legal links
export function processLinkLists({
  linkList,
  locale,
  partner,
  t,
  type,
  includeEnUsLocaleParam,
  newsletterEnabled,
}) {
  return reduce(linkList, (filteredLinks, link) => {
    if (validateLocale(locale, link)
     && validatePartner(partner, link)
     && validateNewsletterEnabledLink(newsletterEnabled, link, partner)) {
      const {
        url,
        includeEnUsLocale,
        titleTranslationKey,
        title,
      } = link;
      const queryString = type?.toUpperCase?.() === 'WU' ? link?.wuQueryString : link?.queryString;
      let finalUrl = link?.url;

      finalUrl = type?.toUpperCase?.() === 'WU' && link?.wuUrl ? link?.wuUrl : link?.url;
      const transformedLink = {
        ...link,
        url: `${finalUrl}${queryString ? `?${queryString}` : ''}`,
        title: titleTranslationKey ? t('wxu-footer', titleTranslationKey) : title,
      };

      const {
        customLanguage,
      } = link;
      // Use specific path if there is one
      const language = (customLanguage && customLanguage[locale]) || '';

      transformedLink.url = interpolateUrl({
        locale,
        path: transformedLink.url || url,
        includeEnUsLocale: includeEnUsLocaleParam ?? includeEnUsLocale,
        customLanguage: language,
      });

      filteredLinks.push(transformedLink);
    }
    return filteredLinks;
  }, []);
}

export const getTermsOfUseLinksSelector = ({
  t,
  shouldUseLocaleParam,
}) => createSelector(
  localeSelector,
  languageCodeSelector,
  specialPartnerSelector,
  serverPageStateQuerySelector,
  (locale, language, partner, query) => {
    if (shouldUseLocaleParam) {
      return (localeParame, includeEnUsLocaleParam) => processLinkLists({
        linkList: cloneDeep(TERMS_OF_USE_LINKS),
        locale: localeParame,
        language,
        partner,
        t,
        type: query?.type,
        includeEnUsLocaleParam,
      });
    }

    return processLinkLists({
      linkList: cloneDeep(TERMS_OF_USE_LINKS),
      locale,
      language,
      partner,
      t,
      type: query?.type,
    });
  }
);

export const getPrivacyPolicyLinksSelector = ({
  t,
  shouldUseLocaleParam,
}) => createSelector(
  localeSelector,
  languageCodeSelector,
  specialPartnerSelector,
  serverPageStateQuerySelector,
  (locale, language, partner, query) => {
    if (shouldUseLocaleParam) {
      return (localeParame) => processLinkLists({
        linkList: cloneDeep(PRIVACY_POLICY_LINKS),
        locale: localeParame,
        language,
        partner,
        t,
        type: query?.type,
      });
    }

    return processLinkLists({
      linkList: cloneDeep(PRIVACY_POLICY_LINKS),
      locale,
      language,
      partner,
      t,
      type: query?.type,
    });
  }
);

export const filterLegalLinksSelector = createSelector(
  privacyHeaderSelector,
  (regime) => {
    const clonedLegalLinks = cloneDeep(LEGAL_LINKS);
    const cookieNoticeLinkIndex = clonedLegalLinks.findIndex(link => link.titleTranslationKey === 'cookieNotice');

    if (!US_REGIMES.includes(regime)) clonedLegalLinks.splice(cookieNoticeLinkIndex, 1);
    if (regime === USA_CCPA) clonedLegalLinks[cookieNoticeLinkIndex] = CALIFORNIA_NOTICE_LINK;

    return clonedLegalLinks;
  }
);

export const getLegalLinksSelector = t => createSelector(
  localeSelector,
  languageCodeSelector,
  specialPartnerSelector,
  serverPageStateQuerySelector,
  filterLegalLinksSelector,
  (locale, language, partner, query, legalLinks) => processLinkLists({
    linkList: cloneDeep(legalLinks),
    locale,
    language,
    partner,
    t,
    type: query?.type,
  })
);

export const getCorporateLinksSelector = t => createSelector(
  localeSelector,
  languageCodeSelector,
  specialPartnerSelector,
  serverPageStateQuerySelector,
  newsletterEnabledSelector,
  (locale, language, partner, query, newsletterEnabled) => processLinkLists({
    linkList: cloneDeep(CORPORATE_LINKS),
    locale,
    language,
    partner,
    t,
    type: query?.type,
    newsletterEnabled,
  })
);

export const getSocialLinks = t => createSelector(
  localeSelector,
  languageCodeSelector,
  specialPartnerSelector,
  serverPageStateQuerySelector,
  (locale, language, partner, query) => {
    const socialLinkList = cloneDeep(SOCIAL_LINKS);

    // eslint-disable-next-line array-callback-return
    socialLinkList.map((link) => {
      link.title = link.titleTranslationKey ? t('wxu-footer', link.titleTranslationKey) : link.title;
    });

    return processLinkLists({
      linkList: socialLinkList,
      locale,
      language,
      partner,
      t,
      type: query?.type,
    });
  }
);

export const dataInventoryUrlSelector = createSelector(
  urlTranslatorSelector,
  urlTranslator => urlTranslator('dataInventory')
);

export const shouldShowKMADataDisclaimerSelector = createSelector(
  isKoreanCSCCodeSelector,
  (isKoreanCSCCode) => isKoreanCSCCode,
);
