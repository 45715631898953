import { select } from 'redux-saga/effects';
import { samsungThemeStateEntityUrlConfigSelector } from '@wxu/theme/src/theme.selectors';

import { EXPERIMENT_STATE_CONFIG_ID } from './constants';
import { trademarkRegistrationStateEntityUrlConfigSelector } from './selectors';

/**
 * Return an array of objects with two fields: 'name' and 'params'.
 * 'name' is the name of the url config to use (see: @wxu/dal url configs)
 * 'params' are all the url config parameters, as specified in the url config
*
* @returns {Object[]} Url configs to fetch
*/
export function* registerApis() {
  return [
    yield select(samsungThemeStateEntityUrlConfigSelector),
    yield select(trademarkRegistrationStateEntityUrlConfigSelector),
    {
      name: 'getMewEntityUrlConfig',
      params: {
        entityType: 'state_config',
        entityId: EXPERIMENT_STATE_CONFIG_ID,
      },
    },
    // TrademarkRegistration
  ];
}
