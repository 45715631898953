import get from 'lodash/get';
import { createSelector } from 'reselect';
import {
  dalSelector,
  createDalKey,
  createDeepEqualSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  localeSelector,
  unitsSystemCodeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import {
  preferredLocationGeocodeSelector,
} from '@wxu/contexts/src/preferred-location/selectors';
import { isLocationBasedPageSelector, serverPageStateQuerySelector, cscHeaderSelector } from '@wxu/contexts/src/page/selectors';
import {
  wwirInsightSelector,
} from '@wxu/selectors/src/weather/Insights';

import {
  WEATHER_MODE_NORMAL,
  WEATHER_MODE_ACTIVE,
  WEATHER_MODE_SEVERE,
  WEATHER_MODES,
  ALERTS_URL_CONFIG_NAME,
  CURRENT_OBS_URL_CONFIG_NAME,
  DAILY_FORECAST_URL_CONFIG_NAME,
  POLLEN_FORECAST_URL_CONFIG_NAME,
} from './constants';
import {
  SEVERE_WEATHER_ALERT_TRIGGERS,
} from './SEVERE_WEATHER_ALERT_TRIGGERS.const';

/* WEATHER ALERTS */

export const alertsUrlConfigParamsSelector = createSelector(
  preferredLocationGeocodeSelector,
  localeSelector,
  (geocode, language) => ({
    geocode,
    language,
  })
);

export const alertsUrlConfigSelector = createSelector(
  alertsUrlConfigParamsSelector,
  params => ({
    name: ALERTS_URL_CONFIG_NAME,
    params,
  })
);

/**
 * Gets the Alerts for the requested page,
 * which is needed to determine whether the
 * weatherMode is SEVERE.
 *
 * gets from "dal", e.g.
 *   getSunWeatherAlertHeadlinesUrlConfig: {
 *     'geocode:37.418,-79.875;language:en-US': {
 *       loaded: true,
 *       loading: false,
 *       data: {
 *         // stuff
 *       },
 *     },
 *   },
 */
export const alertsDataSelector = createDeepEqualSelector(
  dalSelector,
  alertsUrlConfigParamsSelector,
  (dal, params) => {
    const dalKey = createDalKey(params);

    return get(dal, [ALERTS_URL_CONFIG_NAME, dalKey, 'data', 'alerts']);
  },
);

/* POLLEN FORECAST */

export const pollenForecastUrlConfigParamsSelector = createSelector(
  preferredLocationGeocodeSelector,
  localeSelector,
  (geocode, language) => ({
    duration: '3day',
    geocode,
    language,
  })
);

export const pollenForecastUrlConfigSelector = createSelector(
  pollenForecastUrlConfigParamsSelector,
  params => ({
    name: POLLEN_FORECAST_URL_CONFIG_NAME,
    params,
  })
);

export const pollenForecastDataSelector = createDeepEqualSelector(
  dalSelector,
  pollenForecastUrlConfigParamsSelector,
  (dal, params) => {
    const dalKey = createDalKey(params);

    return get(dal, [POLLEN_FORECAST_URL_CONFIG_NAME, dalKey, 'data', 'pollenForecast12hour']);
  },
);

/* DAILY FORECAST */

export const dailyForecastUrlConfigParamsSelector = createSelector(
  preferredLocationGeocodeSelector,
  localeSelector,
  unitsSystemCodeSelector,
  cscHeaderSelector,
  (geocode, language, units, cscCountryCode) => ({
    duration: '7day',
    geocode,
    language,
    units,
    ...cscCountryCode === 'KR' && { cscCountryCode },
  })
);

export const dailyForecastUrlConfigSelector = createSelector(
  dailyForecastUrlConfigParamsSelector,
  params => ({
    name: DAILY_FORECAST_URL_CONFIG_NAME,
    params,
  })
);

export const dailyForecastDataSelector = createDeepEqualSelector(
  dalSelector,
  dailyForecastUrlConfigParamsSelector,
  (dal, params) => {
    const dalKey = createDalKey(params);

    return get(dal, [DAILY_FORECAST_URL_CONFIG_NAME, dalKey, 'data']);
  },
);

/* CURRENT OBSERVATIONS */

export const currentObsUrlConfigParamsSelector = createSelector(
  preferredLocationGeocodeSelector,
  localeSelector,
  unitsSystemCodeSelector,
  cscHeaderSelector,
  (geocode, language, units, cscCountryCode) => ({
    geocode,
    language,
    units,
    ...cscCountryCode === 'KR' && { cscCountryCode },
  })
);

export const currentObsUrlConfigSelector = createSelector(
  currentObsUrlConfigParamsSelector,
  params => ({
    name: CURRENT_OBS_URL_CONFIG_NAME,
    params,
  })
);

/**
 * Gets the current observations for the page.
 *
 * @param  {Object} state
 * @return {Object} Current observations data
 */
export const currentObsDataSelector = createSelector(
  dalSelector,
  currentObsUrlConfigParamsSelector,
  (dal, params) => {
    const dalKey = createDalKey(params);

    return get(dal, [CURRENT_OBS_URL_CONFIG_NAME, dalKey, 'data']);
  }
);

export const skyCodeSelector = createSelector(
  currentObsDataSelector,
  data => get(data, 'iconCode', null)
);

/**
 * Return whether it's daytime or nighttime.
 *
 * @param  {Object} state
 * @return {string}
 */
export const dayPartSelector = createSelector(
  currentObsDataSelector,
  (data) => {
    const dayOrNight = get(data, 'dayOrNight', '');

    switch (dayOrNight) {
      case 'D':
        return 'day';
      case 'N':
        return 'night';
      default:
        return '';
    }
  }
);

function hasSevereAlerts(alerts) {
  const hasAlerts = alerts && alerts.length;
  const hasSevereWeatherAlertTriggers = hasAlerts && alerts.some(
    ({ phenomena, significance, severityCode }) => (
      severityCode === 1
      || SEVERE_WEATHER_ALERT_TRIGGERS.some(
        trigger => (
          phenomena === trigger.phenomena
          && significance === trigger.significance
        )
      )
    )
  );

  return hasSevereWeatherAlertTriggers;
}

export const weatherModeQuerySelector = createSelector(
  serverPageStateQuerySelector,
  pageQuery => get(pageQuery, 'weatherMode')
);

/**
 * Gets the Weather Mode; either 'severe' or 'active'
 */
export const weatherModeSelector = createDeepEqualSelector(
  alertsDataSelector,
  isLocationBasedPageSelector,
  weatherModeQuerySelector,
  wwirInsightSelector,
  (
    alerts = [],
    isLocationBasedPage,
    weatherModeQuery,
    wwirInsight,
  ) => {
    const hasPrecip = !!wwirInsight ?? false;

    if (!isLocationBasedPage) {
      return WEATHER_MODE_NORMAL;
    }

    if (weatherModeQuery && WEATHER_MODES.includes(weatherModeQuery)) {
      return weatherModeQuery;
    }

    if (hasSevereAlerts(alerts)) {
      return WEATHER_MODE_SEVERE;
    }

    if (hasPrecip) {
      return WEATHER_MODE_ACTIVE;
    }

    return WEATHER_MODE_NORMAL;
  },
);

/**
 * Returns true if weather mode is active
 *
 * @return boolean
 */
export const activeWeatherModeSelector = createDeepEqualSelector(
  weatherModeSelector,
  (
    weatherMode,
  ) => weatherMode === WEATHER_MODE_ACTIVE
);
