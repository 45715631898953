import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  getTypeAndValueObject,
  locationApiCallsSelector,
  formatPresentationName,
  normalizeGeocode,
  pageLocationPlaceIdSelector,
} from '@wxu/contexts/src/page-location/selectors';
import {
  rawRecentLocationsSelector,
  rawFavoriteLocationsSelector,
  userStateSelector,
} from '@wxu/contexts/src/user/selectors';
import { geoIPGeocodeSelector } from '@wxu/contexts/src/user-location/selectors';
import {
  createDeepEqualSelector,
  dalSelector,
  createDalKey,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  countryCodeSelector,
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import { isKoreanCSCCodeSelector, isLocationBasedPageSelector } from '@wxu/contexts/src/page/selectors';
import { getCICookieObj } from '@wxu/ci-cookie';

export const preferredLocationIdSelector = createSelector(
  isLocationBasedPageSelector,
  pageLocationPlaceIdSelector,
  rawFavoriteLocationsSelector,
  rawRecentLocationsSelector,
  geoIPGeocodeSelector,
  (
    isLocationBasedPage,
    pagePlaceId,
    rawFavoriteLocations,
    recentLocations,
    geoIPGeocode
  ) => {
    // for location-based pages, return the page's place ID
    if (isLocationBasedPage) {
      return pagePlaceId;
    }
    // non-location-based pages rely on location information
    // found client-side
    if (!__CLIENT__) return;
    const placeIdRegex = /^([0-9a-f]){32,}$/;

    // if saved locations exist, return the most recent one
    if (placeIdRegex.test(rawFavoriteLocations[0]?.placeID)) {
      return rawFavoriteLocations[0].placeID;
    }
    // if recent locations exist, return the most recent one
    if (recentLocations?.[0]) {
      return recentLocations[0];
    }
    if (geoIPGeocode) {
      return geoIPGeocode;
    }
    // is there a CI cookie? if so, return loc info from cookie
    // no location exists, return null;

  }
);

// TODO: redo this description
/**
 * Get the type and cleaned-up value of the "dirty" pageParamLocId.
 * Returns an object containing both the loc's type and its value,
 * since they're determined at the same time by the same logic.
 * The type options are:
 * - "geocode"
 * - "locId" (for legacy locIds, any type other than 4 or 9)
 * - "postalKey" (converted from legacy locIds type 4)
 * - "iataCode" (converted from legacy locIds type 9)
 * - "placeId"
 * - "canonicalCityId"
 * - "none" (if request path contained no pageParamLocId)
 *
 * This selector provides the calculated, memoized values to the
 * individual selectors for type and values:
 * - pageParamLocTypeSelector
 * - pageParamLocValueSelector
 *
 * @returns {*} typeAndValue
 */
export const userLocTypeAndValueSelector = createDeepEqualSelector(
  preferredLocationIdSelector,
  (preferredLocation) => {
    if (!preferredLocation) {
      return {
        value: null,
        type: 'none',
      };
    }

    const locationToUse = {
      type: null,
      locId: decodeURIComponent(preferredLocation),
    };

    return getTypeAndValueObject(locationToUse);
  },
);

/**
 * Get the name and params for all the calls required
 * in registerApis.js for the location call/calls.
 *
 * This can be used in registerApis.js, of course; but
 * also re-used with dalSelector to select data from
 * within the responses.
 */
export const preferredLocationApiCallsSelector = createDeepEqualSelector(
  localeSelector,
  userLocTypeAndValueSelector,
  (language, locTypeAndValue) => {
    const { type, value } = locTypeAndValue || {};
    const params = {
      language,
      [type]: value,
    };

    if (type === 'locId') {
      params.locationType = 'locid'; // SUN casing not consistent
    }

    return {
      getSunV3LocationPointUrlConfig: params,
    };
  },
);

/**
 * Gets the raw location v3 response
 */
export const preferredLocationResponseSelector = createDeepEqualSelector(
  dalSelector,
  preferredLocationApiCallsSelector,
  locationApiCallsSelector,
  isLocationBasedPageSelector,
  (dal, apiCalls, locationApiCalls, isLocationBasedPage) => {
    const callKey = 'getSunV3LocationPointUrlConfig';
    const rootObj = dal[callKey] || {};

    let params = apiCalls[callKey] || {};

    if (isLocationBasedPage) {
      params = locationApiCalls[callKey];
    }
    const paramsKey = createDalKey(params);

    return get(rootObj, paramsKey);
  },
);

/**
 * Gets pageLocation data if successfully loaded
 */
export const preferredLocationDataSelector = createDeepEqualSelector(
  preferredLocationResponseSelector,
  /**
   * @param  {Object<string,*>} response
   * @return {Object<string,*>}
   */
  (response) => {
    const loaded = get(response, 'loaded');

    if (!loaded) {
      return null;
    }

    const data = get(response, 'data');

    if (!data || data.errors) {
      return null;
    }

    return response.data.location;
  },
);

/**
 * Gets displayName; e.g. "Atlanta"
 */
export const preferredLocationDisplayNameSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'displayName', ''),
);

/**
 * Gets adminDistrict; e.g. "Georgia"
 */
export const preferredLocationAdminDistrictSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'adminDistrict', ''),
);

/**
 * Gets adminDistrictCode; e.g. "GA"
 */
export const preferredLocationAdminDistrictCodeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'adminDistrictCode', ''),
);

/**
 * Gets countryCode; e.g. "US"
 */
export const preferredLocationCountryCodeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'countryCode', ''),
);

/**
 * Gets country name; e.g. "United States"
 */
export const preferredLocationCountrySelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'country', ''),
);

/**
 * Gets countyId; e.g. "GAC121"
 */
export const preferredLocationCountyIdSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'countyId', ''),
);

/**
 * Gets the disputedArea; e.g. true
 */
export const preferredLocationDisputedAreaSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  locData => get(locData, 'disputedArea', false),
);

/**
 * Gets geocode
 */
export const preferredLocationGeocodeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  (locData) => {
    if (!locData) {
      return;
    }
    return normalizeGeocode(`${locData.latitude},${locData.longitude}`);
  },
);

/**
 * Gets a json object of default locations keyed by country
 */
export const defaultLocationsSelector = createDeepEqualSelector(
  userStateSelector,
  /**
   * @return {object}
   */
  userState => get(userState, 'defaultLocations', {})
);

/**
 * Gets placeId; e.g. "3474be034807c5d9ff1e0183e965448c7abbb337e21116050616f1fdf84d721b"
 * If there is no TWC-GeoIP-Lat and TWC-GeoIP-Long, return default location.
 */
export const preferredLocationPlaceIdSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  defaultLocationsSelector,
  /**
   * @return {string}
   */
  (locData, defaultLocations) => {
    if (locData) {
      return get(locData, 'placeId', '');
    }
    if (__CLIENT__) {
      const cookieObj = getCICookieObj();
      const { 'TWC-GeoIP-Country': countryCode } = cookieObj;

      return defaultLocations[countryCode] || null;
    }
    return null;
  }
);

/**
 * Gets dmaCd; e.g. "524"
 */
export const preferredLocationDmaCdSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'dmaCd', ''),
);

/**
 * Gets postalCode; e.g. "30337"
 */
export const preferredLocationPostalCodeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'postalCode', ''),
);

/**
 * Gets the city; e.g. "Atlanta"
 */
export const preferredLocationCitySelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {string}
   */
  locData => get(locData, 'city', ''),
);

/**
 * Gets the canonicalCityId; e.g. "36fc5df08dce7eb0bba1d99cfe8973ec407f8253eb601fe8be4358220a63866a"
 */
export const preferredLocationCanonicalCityIdSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  locData => get(locData, 'canonicalCityId', ''),
);

/**
 * Gets ianaTimeZone; e.g. "America/New_York"
 */
export const preferredLocationIanaTimeZoneSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  (locData) => {
    let ianaTimeZone = get(locData, 'ianaTimeZone', '');

    // Provide dateTime compatible timezone fallback if ianaTimeZone is null or a number
    if (!ianaTimeZone || !ianaTimeZone.includes('/')) {
      ianaTimeZone = 'Etc/UTC';
    }

    /**
     * @return {string}
     */
    return ianaTimeZone;
  }
);

/**
 * Gets latitude
 */
export const preferredLocationLatitudeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {number}
   */
  locData => normalizeLatOrLong(get(locData, 'latitude', undefined)),
);

/**
 * Gets longitude
 */
export const preferredLocationLongitudeSelector = createDeepEqualSelector(
  preferredLocationDataSelector,
  /**
   * @return {number}
   */
  locData => normalizeLatOrLong(get(locData, 'longitude', undefined)),
);

/**
 * Gets the presentation name for the preferred location
 */
export const preferredLocationPresentationNameSelector = createDeepEqualSelector(
  preferredLocationDisplayNameSelector,
  preferredLocationAdminDistrictSelector,
  preferredLocationAdminDistrictCodeSelector,
  preferredLocationCountryCodeSelector,
  preferredLocationCountrySelector,
  preferredLocationDisputedAreaSelector,
  countryCodeSelector,
  (
    displayName,
    adminDistrict,
    adminDistrictCode,
    countryCode,
    country,
    disputedArea,
    localeCountryCode,
  ) => {
    const presentationName = formatPresentationName({
      displayName,
      adminDistrict,
      adminDistrictCode,
      countryCode,
      country,
      disputedArea,
      localeCountryCode,
    });

    return presentationName;
  }
);

export const favoriteLocationIdSelector = createSelector(
  rawFavoriteLocationsSelector,
  (rawFavoriteLocations) => {
    // Copied regex test from preferredLocationIdSelector
    const placeIdRegex = /^([0-9a-f]){32,}$/;

    if (placeIdRegex.test(rawFavoriteLocations[0]?.placeID)) {
      return rawFavoriteLocations[0].placeID;
    }
    return null;
  }
);

export const favoriteLocTypeAndValueSelector = createDeepEqualSelector(
  favoriteLocationIdSelector,
  (favoriteLocation) => {
    if (!favoriteLocation) {
      return {
        value: null,
        type: 'none',
      };
    }

    const locationToUse = {
      type: null,
      locId: decodeURIComponent(favoriteLocation),
    };

    return getTypeAndValueObject(locationToUse);
  },
);

export const favoriteLocationApiCallsSelector = createDeepEqualSelector(
  localeSelector,
  favoriteLocTypeAndValueSelector,
  (language, locTypeAndValue) => {
    const { type, value } = locTypeAndValue || {};
    const params = {
      language,
      [type]: value,
    };

    if (type === 'locId') {
      params.locationType = 'locid'; // SUN casing not consistent
    }

    return {
      getSunV3LocationPointUrlConfig: params,
    };
  },
);

/**
 * Gets the raw location v3 response
 */
export const favoriteLocationResponseSelector = createDeepEqualSelector(
  dalSelector,
  favoriteLocationApiCallsSelector,
  (dal, apiCalls) => {
    const callKey = 'getSunV3LocationPointUrlConfig';
    const rootObj = dal[callKey] || {};
    const params = apiCalls[callKey] || {};
    const paramsKey = createDalKey(params);

    return get(rootObj, paramsKey);
  },
);

/**
 * Gets pageLocation data if successfully loaded
 */
export const favoriteLocationDataSelector = createDeepEqualSelector(
  favoriteLocationResponseSelector,
  /**
   * @param  {Object<string,*>} response
   * @return {Object<string,*>}
   */
  (response) => {
    const loaded = get(response, 'loaded');

    if (!loaded) {
      return null;
    }

    const data = get(response, 'data');

    if (!data || data.errors) {
      return null;
    }

    return response.data.location;
  },
);

export const mostRecentLocationIdSelector = createSelector(
  rawRecentLocationsSelector,
  (recentLocations) => {
    // if recent locations exist, return the most recent one
    if (recentLocations?.[0]) {
      return recentLocations[0];
    }
    return null;
  }
);

export const mostRecentLocTypeAndValueSelector = createDeepEqualSelector(
  mostRecentLocationIdSelector,
  (mostRecentLocation) => {
    if (!mostRecentLocation) {
      return {
        value: null,
        type: 'none',
      };
    }

    const locationToUse = {
      type: null,
      locId: decodeURIComponent(mostRecentLocation),
    };

    return getTypeAndValueObject(locationToUse);
  },
);

export const mostRecentLocationApiCallsSelector = createDeepEqualSelector(
  localeSelector,
  mostRecentLocTypeAndValueSelector,
  (language, locTypeAndValue) => {
    const { type, value } = locTypeAndValue || {};
    const params = {
      language,
      [type]: value,
    };

    if (type === 'locId') {
      params.locationType = 'locid'; // SUN casing not consistent
    }

    return {
      getSunV3LocationPointUrlConfig: params,
    };
  },
);

/**
 * Gets the raw location v3 response
 */
export const mostRecentLocationResponseSelector = createDeepEqualSelector(
  dalSelector,
  mostRecentLocationApiCallsSelector,
  (dal, apiCalls) => {
    const callKey = 'getSunV3LocationPointUrlConfig';
    const rootObj = dal[callKey] || {};
    const params = apiCalls[callKey] || {};
    const paramsKey = createDalKey(params);

    return get(rootObj, paramsKey);
  },
);

/**
 * Gets pageLocation data if successfully loaded
 */
export const mostRecentLocationDataFromLocationPointSelector = createDeepEqualSelector(
  mostRecentLocationResponseSelector,
  /**
   * @param  {Object<string,*>} response
   * @return {Object<string,*>}
   */
  (response) => {
    const loaded = get(response, 'loaded');

    if (!loaded) {
      return null;
    }

    const data = get(response, 'data');

    if (!data || data.errors) {
      return null;
    }

    return response.data.location;
  },
);

export const mostRecentLocationDataFromLocationSearchSelector = createDeepEqualSelector(
  dalSelector,
  mostRecentLocationIdSelector,
  (dal, mostRecentLocationId) => {
    const callKey = 'getSunV3LocationSearchUrlConfig';
    const calls = dal[callKey] || {};

    for (const paramsKey of Object.keys(calls)) {
      const location = calls?.[paramsKey]?.data?.location;
      const idx = location?.placeId?.indexOf?.(mostRecentLocationId);

      if (idx >= 0) {
        const entries = Object.keys(location).map((k) => [k, location[k][idx]]);

        return Object.fromEntries(entries);
      }
    }

    return null;
  }
);

export const mostRecentLocationDataSelector = createDeepEqualSelector(
  mostRecentLocationDataFromLocationPointSelector,
  mostRecentLocationDataFromLocationSearchSelector,
  (fromLocationPoint, fromLocationSearch) => fromLocationPoint ?? fromLocationSearch
);

/**
 * HELPER normalizeLatOrLong(latOrLong)
 *
 * @param {*} latOrLong
 */
export function normalizeLatOrLong(latOrLong) {
  const fixedLatorLong = (+`${Math.round(`${latOrLong}e2`)}e-2`).toFixed(2);

  return fixedLatorLong;
}

export const isKoreaCscLocationSelector = createSelector(
  isKoreanCSCCodeSelector,
  preferredLocationCountryCodeSelector,
  (isKoreaCsc, countryCode) => isKoreaCsc && countryCode === 'KR'
);
