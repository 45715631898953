import { createSelector } from 'reselect';
import {
  serverPageStateQuerySelector,
  pageHeadersSelector,
  pageKeySelector,
  deviceClassSelector,
} from '@wxu/contexts/src/page/selectors';
import { isSamsungPartnerSelector } from '@wxu/contexts/src/partner';
import { createDeepEqualSelector, getDalDataSelector } from '@wxu/contexts/src/redux-dal/selectors';
import { getEnv } from '@wxu/env';
import lightTheme from './LightTheme.scss';
import darkTheme from './DarkTheme.scss';
import samsungLight from './samsung/SamsungLight.scss';
import samsungDark from './samsung/SamsungDark.scss';

export const samsungThemeStateEntityUrlConfigSelector = () => ({
  name: 'getMewEntityUrlConfig',
  params: {
    entityType: 'state_config',
    entityId: '9b7cb50b-cf4c-4e5f-9d99-947ed05e71ff',
  },
});

/**
 * Returns the samsung theme state config object
 */
export const samsungThemeConfigSelector = createDeepEqualSelector(
  samsungThemeStateEntityUrlConfigSelector,
  getDalDataSelector,
  (config, getDataFromConfig): {
    allowedMobilePageKeys: string[]
    allowedDesktopPageKeys: string[]
  } => getDataFromConfig(config)?.data?.attributes?.value
);

/**
 * Moonracer configuration to enable switching Samsung theme by par value
 */
export const moonracerSamsungThemeEnabledSelector = createSelector(
  isSamsungPartnerSelector,
  samsungThemeConfigSelector,
  pageKeySelector,
  deviceClassSelector,
  (isSamsungPartner, samsungThemeConfig, pageKey, deviceClass): boolean => isSamsungPartner
    && samsungThemeConfig?.[deviceClass === 'mobile' ? 'allowMobilePageKeys' : 'allowDesktopPageKeys']?.includes(pageKey)
);

/**
 * ENV configuration to enable switching Samsung theme by par value
 */
export const envSamsungThemeEnabledSelector = createSelector(
  isSamsungPartnerSelector,
  (isSamsungPartner): boolean => isSamsungPartner && getEnv('FORCE_SAMSUNG_THEME')
);

const themeDefinitions = [
  'dark',
  'light',
  'samsungLight',
  'samsungDark',
];

/**
 * Ensures that the themeName matches a list of valid theme values
 */
function isValidTheme(themeName): boolean {
  return themeDefinitions.includes(themeName);
}

export type ThemeName = 'light' | 'dark' | 'samsungLight' | 'samsungDark' | '';
export type ThemeCategory = 'twc' | 'samsung';
export interface ThemeContext {
  themeName: ThemeName;
  themeCategory: ThemeCategory;
  isSamsung: boolean;
}

/**
 * Returns the theme query param value
 */
export const themeParamSelector = createSelector(
  serverPageStateQuerySelector,
  (pageQuery): ThemeName => (
    isValidTheme(pageQuery?.theme) ? pageQuery?.theme : ''
  ) as ThemeName
);

/**
 * Returns the twc-theme header value
 */
export const themeHeaderSelector = createSelector(
  pageHeadersSelector,
  (pageHeaders): ThemeName => (
    isValidTheme(pageHeaders?.['twc-theme']) ? pageHeaders?.['twc-theme'] : ''
  ) as ThemeName
);

/**
 * Checks that a theme is defined and then returns that theme or default 'light' value if there was no theme specified
 */
export const themeNameSelector = createSelector(
  themeParamSelector,
  themeHeaderSelector,
  moonracerSamsungThemeEnabledSelector,
  envSamsungThemeEnabledSelector,
  (
    themeParam,
    themeHeader,
    moonracerSamsungThemeEnabled,
    envSamsungThemeEnabled,
  ): ThemeName => {
    const themeName = themeHeader || themeParam;

    if (moonracerSamsungThemeEnabled || envSamsungThemeEnabled) {
      // TODO Remove when implementing samsungDark Theme
      if (themeName === 'samsungDark') {
        return 'samsungLight';
      }

      return themeName || 'samsungLight';
    }

    return themeName || 'light';
  }
);

/**
 * Returns the theme category to use for targeting layout specific changes that are common across
 * a light/dark pair of themes
 */
export const themeCategorySelector = createSelector(
  themeNameSelector,
  (themeName): ThemeCategory => {
    const themeCategory = themeName.includes('samsung') ? 'samsung' : 'twc';

    return themeCategory;
  }
);

/**
 * Returns object containing theme information to be used by Context Provider
 *
 * Initially only includes themeName & themeCategory, but this could be extended to include
 * other details such as color definitions or a method to change the theme.
 */
export const themeSelector = createSelector(
  themeNameSelector,
  themeCategorySelector,
  (themeName, themeCategory): ThemeContext => ({
    themeName,
    themeCategory,
    isSamsung: themeCategory === 'samsung',
  })
);

/**
 * Returns theme specific CSS module object that can be loaded by the page layout
 */
export const themeStylesSelector = createSelector(
  themeNameSelector,
  (theme) => {
    switch (theme) {
      case 'light':
        return lightTheme;
        break;
      case 'dark':
        return darkTheme;
        break;
      case 'samsungLight':
        return samsungLight;
        break;
      case 'samsungDark':
        return samsungDark;
        break;
      default:
        return lightTheme;
    }
  }
);

/**
 * Returns theme specific CSS classes which should be applied to the app wrapper
 */
export const themeClassSelector = createSelector(
  themeNameSelector,
  themeCategorySelector,
  (themeName, themeCategory) => (themeName ? `${themeName}Theme ${themeCategory}Theme` : '')
);
