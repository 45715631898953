export const REDUCER_KEY = 'page';
export const SET_PAGE_STATE = 'WXU/PAGE/SET_PAGE_STATE';
export const LITE_CONTENT_PAGE_KEYS = [
  'privacyPolicy',
  'adChoices',
  'termsOfUse',
  // add more here later?
];
export const GOTO_PAGE = 'WXU/CHANGE/PAGE/LOCATION';
export const ADBLOCK_STATUS = 'WXU/ADBLOCK_STATUS';
export const OBJECT_PROTO_KEYS = new Set(Object.getOwnPropertyNames(Object.prototype));
export const EXPERIMENT_STATE_CONFIG_ID = '3f7db82b-3ad6-4172-bf96-804c24ed4c47';

export const CSC_KEY = 'csc';
