import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  createDeepEqualSelector,
  getDalDataSelector,
  dalSelector,
  createDalKey,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  REDUCER_KEY,
  LITE_CONTENT_PAGE_KEYS,
  EXPERIMENT_STATE_CONFIG_ID,
} from '../constants';

/**
 * Get the state stored under the 'page' key.
 * @param  {Object<string,*>} state
 * @return {Object<string,*>}
 */
export const pageSelector = state => state?.[REDUCER_KEY] ?? null;

export const pageDomainSelector = state => state?.[REDUCER_KEY]?.domain;

export const pageHeadersSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {Object<string,string>}
   */
  pageState => pageState?.headers ?? null,
);

export const pageSubscriptionTierSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  headers => headers?.['twc-subs'] ?? 'none'
);

export const stickyTestVariantSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  headers => headers?.['twc-sticky-variant'] ?? '',
);

export const killswitchSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  headers => headers?.['twc-ads-killswitch'] ?? '',
);

export const siteModeOverrideSelector = createSelector(
  pageHeadersSelector,
  headers => headers?.['twc-site-mode'] ?? '',
);

// Returns the value of the twc-experiment header
export const experimentHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  headers => {
    const header = headers?.['twc-experiment'] ?? null;

    if (header) {
      if (__CLIENT__) {
        return JSON.parse(window?.atob(header));
      }
      return JSON.parse(Buffer.from(header, 'base64'));
    }
    return {};
  },
);

// Gets the experiment state config from moonracer
export const experimentStateConfigSelector = createDeepEqualSelector(
  dalSelector,
  (dalState) => {
    const dalKey = createDalKey({ entityType: 'state_config', entityId: EXPERIMENT_STATE_CONFIG_ID });
    const experimentConfig = get(dalState, ['getMewEntityUrlConfig', dalKey, 'data', 'data', 'attributes', 'value'], {});

    return experimentConfig;
  }
);

// Returns key used in context param to determine page configurations for experiments
export const experimentSelector = createSelector(
  experimentStateConfigSelector,
  experimentHeaderSelector,
  (experimentStateConfig, experiments) => {
    // loop through experimentConfig to see if the experimentHeader matches any of the experiment names
    // if experiment state config contains a matching key value in experiments, return the key

    let activeExperiment;
    const experimentStateConfigKeys = Object.keys(experimentStateConfig);
    const experimentKeys = Object.keys(experiments);

    experimentStateConfigKeys.forEach((experimentKey) => {
      if (experimentKeys.includes(experimentKey)) {
        activeExperiment = experimentKey;
      }
    });

    return activeExperiment;
  }
);

export const pagePathSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  // remove the trailing slash from the path before it can be used by article selector
  pageState => pageState?.path?.replace(/\/$/, '') ?? '',
);

export const pagePathPatternSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  pageState => pageState?.pathPattern ?? '',
);

export const pageRefererSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  pageState => pageState?.referer ?? null,
);

export const pageRequestBodySelector = createSelector(
  pageSelector,
  /**
   * @param  {string} pagePathPattern
   * @return {*}
   */
  pageState => pageState?.requestBody ?? null,
);

export const serverPageStateQuerySelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {Object<string,string>}
   */
  pageState => pageState?.query ?? null,
);

export const pageParamsSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {Object<string,string>}
   */
  pageState => pageState?.params ?? null,
);

export const pageKeySelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  pageState => pageState?.pageKey ?? '',
);

export const isLiteContentSelector = createSelector(
  pageKeySelector,
  /**
   * @param  {string} pageKey
   * @return {boolean}
   */
  pageKey => LITE_CONTENT_PAGE_KEYS.includes(pageKey),
);


/**
 * Get "proposed" locId requested in page params, if any;
 * returns undefined if path doesn't specify a location.
 */
export const pageParamsLocIdSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  pageState => pageState?.params?.locId ?? '',
);


/**
 * Gets the contextListId associated with the page.
 * This indicates which list of contexts to process.
 */
export const contextListIdSelector = createSelector(
  pageSelector,
  /**
   * @param  {Object<string,*>} pageState
   * @return {string}
   */
  pageState => pageState?.contextListId ?? '',
);

export const connectionSpeedSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,*>} headers
   * @return {string}
   */
  (headers) => {
    const defaultSpeed = '4g';
    const allowList = [defaultSpeed, '3g'];
    const twcConnectionSpeed = headers?.['twc-connection-speed'] ?? defaultSpeed;
    const connectionSpeed = twcConnectionSpeed.toLowerCase();

    return allowList.includes(connectionSpeed) ? connectionSpeed : defaultSpeed;
  },
);

export const deviceClassSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-device-class'] ?? 'mobile',
);

export const isMobileSelector = createSelector(
  deviceClassSelector,
  (deviceClass) => deviceClass === 'mobile'
);

export const unitHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-unit'] ?? '',
);

export const partnerHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-partner'] ?? '',
);

export const cscHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-csc'] ?? '',
);

/**
 * Returns the user's regime. (aka regimeSelector)
 */
export const privacyHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-privacy'] ?? 'exempt',
);

export const experienceHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-experience'] ?? '',
);

export const experienceQuerySelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {string} pageQuery
   * @return {string}
   */
  pageQuery => pageQuery?.experience ?? '',
);

export const pageQueryLocaleSelector = createSelector(
  serverPageStateQuerySelector,
  pageQuery => pageQuery?.locale ?? '',
);

export const parSelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => queryParams?.par ?? '',
);

export const cscQueryParamSelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => (queryParams?.cscCountryCode ?? queryParams?.csccountrycode ?? ''),
);

export const isKoreanCSCCodeSelector = createSelector(
  cscHeaderSelector,
  /**
   * @param  {string} cscCountryCode
   * @return {boolean}
   */
  cscCountryCode => cscCountryCode === 'KR',
);

export const queryParamLocIdSelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => queryParams?.locId ?? '',
);

export const queryParamPlaceIdSelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => queryParams?.placeId ?? '',
);

export const queryParamCanonicalCityIdSelector = createSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => queryParams?.canonicalCityId ?? '',
);

export const pageLocaleSelector = createSelector(
  pageParamsSelector,
  /**
   * @param  {Object<string,string>} pageParams
   * @return {string}
   */
  pageParams => pageParams?.locale ?? '',
);

export const pageCollectionIDSelector = createSelector(
  pageParamsSelector,
  pageKeySelector,
  pagePathSelector,
  /**
   * @param  {Object<string,string>} pageParams
   * @param {String} pageKey
   */
  (pageParams, pageKey, pagePath) => {
    // medical-reviewer could be an index page or a bio page, so check for it first
    if (pagePath.includes('medical-reviewer')) return ['bios/medical-reviewer'];
    if (pageKey === 'bioPage') return ['bios'];
    if (pageKey === 'commerce') return ['atmosphere'];
    return pageParams?.collectionId ?? null;

  }
);


export const countryCodeParamSelector = createSelector(
  pageLocaleSelector,
  /**
   * @param  {Object<string,string>} pageParams
   * @return {string}
   */
  pageLocaleParam => pageLocaleParam?.split('-')?.[1] ?? '',
);

export const trademarkRegistrationStateEntityUrlConfigSelector = () => ({
  name: 'getMewEntityUrlConfig',
  params: {
    entityType: 'state_config',
    entityId: 'f33a3445-ea5d-4c5d-953c-2a0a63ff8edb',
  },
});

export const trademarkRegistrationConfigSelector = createDeepEqualSelector(
  trademarkRegistrationStateEntityUrlConfigSelector,
  getDalDataSelector,
  (config, getDataFromConfig) => getDataFromConfig(config)?.data?.attributes?.value
);

export const isTrademarkRegisteredForCountryCodeSelector = createSelector(
  countryCodeParamSelector,
  trademarkRegistrationConfigSelector,
  (countryCode, trademarkRegistrationConfig) => {
    const countryCodesWithoutRegisteredTrademark = trademarkRegistrationConfig?.countryCodesWithoutRegisteredTrademark;

    if (!countryCodesWithoutRegisteredTrademark) return false;
    return !countryCodesWithoutRegisteredTrademark.includes(countryCode);
  },
);

export const isLocationBasedPageSelector = createSelector(
  queryParamLocIdSelector,
  pagePathPatternSelector,
  /**
   * @param  {string} pagePathPattern
   * @return {Boolean}
   */
  (queryParamLocId, pagePathPattern) => pagePathPattern.includes(':locId') || !!queryParamLocId,
);

export const isUnconventionalFrontEndApiSelector = createSelector(
  pageKeySelector,
  pageKey => {
    const frontEndApiPageKeys = [
      'moduleService',
    ];

    return frontEndApiPageKeys.includes(pageKey);
  }
);

// Returns true if page follows the wxu-web conventional
// front end API pattern:
//   1. Url is consumed by api/v[0-9]
//   2. Has experience query
//   3. Has locale query
// or
// Returns true if pageKey is added to frontEndApiPageKeys.
// (useful for front end APIs that don't follow the wxu-web conventional
//  pattern)
export const isFrontEndApiSelector = createSelector(
  isUnconventionalFrontEndApiSelector,
  pagePathSelector,
  experienceQuerySelector,
  pageQueryLocaleSelector,
  (isUnconventionalFrontEndApi, pagePath, experienceQuery, localeQuery) => {
    const conventionalFrontEndApiPattern = Array.isArray(pagePath.match(/api\/v[0-9]/g))
     && !!experienceQuery && !!localeQuery;

    return isUnconventionalFrontEndApi || conventionalFrontEndApiPattern;
  }
);

export const subscriptionsEnabledSelector = createSelector(
  pageLocaleSelector,
  (locale) => {
    if (!locale) {
      return true;
    }

    const validLocale = ['en-US'].indexOf(locale) > -1;

    return validLocale;
  }
);

export const noAdsSelector = createSelector(
  killswitchSelector,
  subscriptionsEnabledSelector,
  pageSubscriptionTierSelector,
  (twcKillswitch, subscriptionsEnabled, twcSubscriptionTier) => {
    const killswitches = twcKillswitch && twcKillswitch.split(',') || [];
    const validSubscription = twcSubscriptionTier !== 'none';
    const hardStop = killswitches.includes('ads') || killswitches.includes('allthethings');
    const noAds = hardStop || (subscriptionsEnabled && validSubscription);

    return noAds;
  }
);

/**
 * Dsr Page Selectors
 */
export const isLegacyDsrSelector = createSelector(
  pagePathPatternSelector,
  pagePattern => pagePattern === '/:locale/dsr'
);

// Used in Dsr 2.0 also, as csrf-token is not seeing
// in headers.
export const dsrLegacyCsrfHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-csrf'] ?? '',
);

export const geoipCountryHeaderSelector = createSelector(
  pageHeadersSelector,
  /**
   * @param  {Object<string,string>} headers
   * @return {string}
   */
  headers => headers?.['twc-geoip-country'] ?? '',
);

// Dsr 2.0 field selectors
// Body Request App Data Selectors
export const userIdBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.userId,
);

export const appIdBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.appId,
);

export const versionBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.version,
);

export const adIdBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.adId,
);

export const serviceProvidersBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.serviceProviders,
);

export const enabledUserDataInventoryBodyRequestSelector = createSelector(
  pageRequestBodySelector,
  bodyRequest => bodyRequest?.enabledUserDataInventory,
);

// Dsr 2.0 web query params
// NERF and WU
export const userIdQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.userId
);

export const serviceProvidersQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.serviceProviders
);

export const regimeQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.regime
);

export const countryCodeQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.countryCode
);

export const isUserLoggedInQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.isUserLoggedIn === 'true'
);

export const isUserPremiumQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.isUserPremium === 'true'
);

export const emailQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.email
);

export const actionQuerySelector = createSelector(
  serverPageStateQuerySelector,
  queryParams => queryParams?.action
);

export const appIdOverrideSelector = createSelector(
  serverPageStateQuerySelector,
  (queryParams) => queryParams?.appIdOverride
);

/**
 * end of Dsr Page Selectors
 */

// App Id Selectors
export const isTwcAppIdSelector = createSelector(
  appIdOverrideSelector,
  appIdBodyRequestSelector,
  (appIdOverride, appIdBody) => appIdBody?.includes('twc') || appIdOverride?.includes('twc')
);

export const isWuAppIdSelector = createSelector(
  appIdOverrideSelector,
  appIdBodyRequestSelector,
  (appIdOverride, appIdBody) => appIdBody?.includes('wu') || appIdOverride?.includes('wu')
);

/**
 *
 * @param {*} state
 * @returns boolean
 */
export const hasAdBlockerSelector = state => state?.[REDUCER_KEY]?.adblocker ?? false;
